export const featureFlags = {
  INSTANT_REFUNDS: 'instant-refunds',
  RECHARGE_ENABLED: 'recharge-refunds',
  STATIC_WORKFLOW_TEMPLATES: 'static-workflow-templates',
  STATIC_WORKFLOW_TEMPLATES_RETURNLY_TAGS: 'static-workflow-templates-returnly-tags',
  PACKING_SLIP_TRANSLATION: 'packing-slip-translation',
  SHOP_SCOPED_ROLES_ENABLED: 'shop-scoped-roles-enabled',
  REJECT_ITEM_EDIT_RETURN_ENABLED: 'reject-item-edit-return-enabled',
  WORKFLOWS_TESTBENCH: 'workflows-testbench',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  AUTH0_SESSIONS_ENABLED: 'auth0-sessions-enabled',
  REJECT_RETURN_V2: 'reject-return-v2',
  RETURN_REFUND_SERVICE_ENABLED: 'return-refund-service-enabled',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  GOODDATA_ANALYTICS: 'good-data-analytics',
  OFFSET_HANDLING_FEE_EXCEPTIONS: 'offset-handling-fee-exceptions',
  LOOPY_AI: 'loopy-ai',
  NEW_ADMIN_WORKFLOWS: 'new-admin-workflows',
  NEW_SBL_NEGOTIATED_RATE_CARRIERS: 'new-sbl-negotiated-rate-carriers',
  ENABLE_STRIPE_SETUP_INTENT: 'enable-stripe-setup-intent',
  SHIP_BY_LOOP_BACKUP_PAYMENT_METHODS: 'ship-by-loop-backup-payment-methods',
  PRODUCT_DETAIL_PAGE_IMPROVEMENTS: 'product-detail-page-pdp-improvements',
  NEW_SBL_BYOA_CARRIERS: 'new-sbl-byoa-carriers',
  FORCE_OUTBOUND_SHIPMENTS_FOR_SBLWW: 'force-outbound-shipments-for-sblww',
  OFFSET_FREE_WITH_CANADA: 'offset-free-with-canada',
  BUILD_WARRANTY_RULES_BACKEND: 'build-warranty-rules-backend',
  REFACTOR_REBOUND_CARRIER_CHOICE: 'refactor-rebound-carrier-choice',
  SHIPPING_CALCULATOR: 'shipping-calculator',
};
