<template>
  <div class="navigation-header-search">
    <div 
      class="navigation-header-search__input-wrapper"
      role="search"
      aria-label="Global search"
    >
      <svg-icon
        name="search-global"
        class="navigation-header-search__icon"
        aria-hidden="true"
      />

      <base-input
        ref="searchInput"
        v-model.trim="query"
        placeholder="Search"
        class="navigation-header-search__input"
        aria-label="Global search"
        aria-autocomplete="list"
        :aria-expanded="showResults"
        :aria-controls="showResults ? 'search-results-list' : undefined"
        :aria-activedescendant="selectedResult && resultIndex >= 0 ? `search-result-${resultIndex}` : undefined"
        @focus="showResults = true"
        @blur="handleBlur"
        @keydown="handleSearchKeydown"
      />

      <base-button
        v-if="query !== ''"
        class="navigation-header-search__clear"
        type="icon"
        icon="close"
        aria-label="Clear search"
        @click="clearSearch"
        @blur="handleBlur"
      >
        <span class="visually-hidden">Clear search</span>
      </base-button>

      <div
        v-else
        class="navigation-header-search__shortcut"
        aria-hidden="true"
      >
        <p>
          <span v-if="isMac">⌘</span>

          <span v-else>Ctrl + </span>
          K
        </p>
      </div>
    </div>

    <div
      v-show="showResults"
      id="search-results-list"
      ref="resultsWrapper"
      class="navigation-header-search__results-wrapper"
      role="listbox"
      aria-label="Search results"
    >
      <div class="navigation-header-search__results-wrapper-content">
        <span
          v-if="filteredResults.length === 0"
          class="navigation-header-search__result-item no-results"
          role="status"
        >
          <p class="navigation-header-search__result-item-name text-body-semibold">No results</p>

          <p class="navigation-header-search__result-item-description">
            Oops! We couldn't find what you're looking for. Try using our admin navigation in the sidebar or check our <a
              href="https://help.loopreturns.com/"
              class="navigation-header-search__link"
              target="_blank"
              rel="noopener noreferrer"
            >Help Center</a> for further assistance.</p>
        </span>

        <button
          v-for="(result, index) in filteredResults"
          :id="`search-result-${index}`"
          :key="result.name"
          class="navigation-header-search__result-item"
          :class="{ 'is-active': selectedResult?.name === result.name }"
          role="option"
          :aria-selected="selectedResult?.name === result.name"
          @focus="showResults = true"
          @blur="handleBlur"
          @keydown="handleResultKeydown"
          @click="handleResultClick(result)"
        >
          <span class="navigation-header-search__result-item-name text-body-semibold">
            {{ result.name }}
          </span>

          <span class="navigation-header-search__result-item-description">
            {{ result.description }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BaseButton } from '@loophq/ui';
import { BaseInput } from '@loophq/component-library';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { results } from '@/constants/globalHeaderSearchResults';
import router from '@/router';
import { useStore } from 'vuex';
import useSearchTracking from '@/util/composables/useSearchTracking';
import { debounce } from 'lodash';

// Constants
const NO_RESULTS_TRACKING_DELAY = 100; // ms - delay before tracking "No results" to avoid race conditions
const BLUR_HANDLING_DELAY = 100; // ms - delay for blur handling to allow for other interactions
const SEARCH_DEBOUNCE_DELAY = 300; // ms - delay for debouncing search input

const store = useStore();
const query = ref('');
const showResults = ref(false);
const selectedResult = ref(null);
const resultIndex = ref(-1);
const debouncedSearchTerm = ref(''); // Store the debounced search term

// Component refs
const searchInput = ref(null);
const resultsWrapper = ref(null);

/**
 * Detect Mac platform once during component initialization
 * to avoid repeated string operations on every access
 */
const detectMacPlatform = () => {
  // First try userAgentData if available (modern browsers)
  if (navigator?.userAgentData?.platform) {
    return navigator.userAgentData.platform.toLowerCase().indexOf('mac') > -1;
  }
  
  // Fallback to navigator.platform for broader compatibility
  return navigator?.platform?.toLowerCase().indexOf('mac') > -1;
};

// Cache the platform detection result
const isMacPlatform = detectMacPlatform();

/**
 * Computed property for the trimmed query value to avoid repeated trimming
 */
const trimmedQuery = computed(() => query.value.trim());

/**
 * Update the debounced search term
 * Using Lodash's debounce for a more robust implementation
 */
const updateDebouncedSearchTerm = debounce((value) => {
  debouncedSearchTerm.value = value;
}, SEARCH_DEBOUNCE_DELAY);

/**
 * Helper function to get search results data in a consistent format
 * @returns {Object} Object containing search results data
 */
const getSearchResultsData = () => {
  try {
    if (!filteredResults.value) {
      return { hasResults: false, count: 0, names: [] };
    }
    return {
      hasResults: filteredResults.value.length > 0,
      count: filteredResults.value.length,
      names: filteredResults.value.map(result => result.name)
    };
  } catch (error) {
    console.error('Error getting search results data:', error);
    // Return default values in case of error
    return {
      hasResults: false,
      count: 0,
      names: []
    };
  }
};

// Initialize search tracking
const searchTracking = useSearchTracking({
  getUserData: () => store?.state?.userData || null
});

/**
 * Global keyboard shortcut handler for Ctrl+K/Cmd+K to focus the search input
 * @param {KeyboardEvent} e - The keyboard event
 */
const handleKeydown = (e) => {
  // Only handle the keyboard shortcut if it's not already focused
  if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    
    // Focus the search input if it exists
    if (searchInput.value) {
      // For BaseInput component, we need to access the underlying input element
      const inputElement = searchInput.value.$el.querySelector('input');
      if (inputElement && document.activeElement !== inputElement) {
        inputElement.focus();
      }
    }
  }
};

onMounted(() => {
  window?.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
  if (updateDebouncedSearchTerm.cancel) {
    updateDebouncedSearchTerm.cancel();
  }
  window?.removeEventListener('keydown', handleKeydown);
  searchTracking.cleanup();
  
  // Clear refs to help garbage collection
  searchInput.value = null;
  resultsWrapper.value = null;
  selectedResult.value = null;
  query.value = '';
  debouncedSearchTerm.value = '';
});

// Update the watch function to handle real-time results without tracking partial queries
watch(query, (newQuery, oldQuery) => {
  if (newQuery !== oldQuery) {
    resetSelectedResult();

    if (trimmedQuery.value !== '') {
      showResults.value = true;
      
      // Update the debounced search term
      updateDebouncedSearchTerm(trimmedQuery.value);
      
      // Start tracking the typing session
      searchTracking.startTypingSession({
        query: trimmedQuery.value,
        getSearchResults: () => {
          const results = getSearchResultsData();
          return results;
        }
      });
    } else {
      // Immediately update debounced search term for empty queries
      debouncedSearchTerm.value = '';
    }
  }
});

/**
 * Optimized filtered results computation
 * Uses the debounced search term for filtering to reduce computation frequency
 */
const filteredResults = computed(() => {
  // For empty search, return top 5 results with order=1
  if (debouncedSearchTerm.value === '') {
    return results
      .filter(item => item.isVisible && item.order === 1)
      .slice(0, 5);
  }
  
  // For non-empty search, filter and sort results
  const searchTerm = debouncedSearchTerm.value.toLowerCase();
  return results
    .filter(item => 
      item.isVisible &&
      (item.name.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm))
    )
    .sort((a, b) => a.order - b.order);
});

/**
 * Watch for changes in filteredResults to track "No results" scenarios
 * Only tracks when:
 * 1. The user has entered a non-empty query
 * 2. The current results are empty but previous results were not empty
 * 3. The user has stopped typing (typing timer has completed)
 * 4. We're not in the middle of a typing session
 */
watch(filteredResults, (newResults, oldResults) => {
  // Skip if query is empty or we have results
  if (!trimmedQuery.value || newResults.length > 0) {
    return;
  }
  
  // Skip if we're still typing or don't have previous results to compare
  if (searchTracking.isTyping.value || !oldResults || oldResults.length === 0) {
    return;
  }
  
  // Use a local variable to capture the current query to avoid race conditions
  const currentQuery = trimmedQuery.value;
  
  // Add a small delay to ensure typing timer has completed
  setTimeout(() => {
    // Only track if conditions are still valid
    if (currentQuery === trimmedQuery.value && 
      filteredResults.value.length === 0 && 
      !searchTracking.isTyping.value) {
      
      searchTracking.trackNoResults({
        query: currentQuery
      });
    }
  }, NO_RESULTS_TRACKING_DELAY);
});

/**
 * Detects if the user is on a Mac platform using a cross-browser compatible approach
 * Uses cached value from component initialization
 */
const isMac = computed(() => isMacPlatform);

/**
 * Clears the search input and tracks the clear action if needed
 */
const clearSearch = () => {
  // Only track if there was actually a query to clear
  if (trimmedQuery.value !== '') {
    searchTracking.handleSearchClear({
      query: trimmedQuery.value,
      getSearchResults: getSearchResultsData
    });
  }
  
  // Set query to empty string - Vue's reactivity will update the DOM
  query.value = '';
};

/**
 * Resets the selected result state
 * Used when the search query changes or when results need to be cleared
 */
const resetSelectedResult = () => {
  selectedResult.value = null;
  resultIndex.value = -1;
};

/**
 * Scrolls the result at the specified index into view
 * @param {number} index - The index of the result to scroll into view
 */
const scrollResultIntoView = (index) => {
  try {
    if (!resultsWrapper.value) return;
    
    const resultElements = resultsWrapper.value.querySelectorAll('.navigation-header-search__result-item');
    if (!resultElements || resultElements.length <= index || !resultElements[index]) return;
    
    if (typeof resultElements[index].scrollIntoView === 'function') {
      resultElements[index].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  } catch (error) {
    console.error('Error scrolling result into view:', error);
  }
};

/**
 * Selects a result in the specified direction
 * @param {string} direction - The direction to select ('next' or 'previous')
 */
const selectResult = (direction) => {
  if (!filteredResults.value?.length) {
    resultIndex.value = -1;
    selectedResult.value = null;
    return;
  }
  
  const totalResults = filteredResults.value.length;
  const newIndex = direction === 'next'
    ? (resultIndex.value + 1) % totalResults
    : (resultIndex.value - 1 + totalResults) % totalResults;
  
  resultIndex.value = newIndex;
  selectedResult.value = filteredResults.value[newIndex];
  scrollResultIntoView(newIndex);
};

/**
 * Selects the next result in the filtered results list
 * Handles wrapping around to the first result if at the end
 */
const selectNextResult = () => {
  selectResult('next');
};

/**
 * Selects the previous result in the filtered results list
 * Handles wrapping around to the last result if at the beginning
 */
const selectPreviousResult = () => {
  selectResult('previous');
};

/**
 * Helper function to handle Escape key press
 * Closes the search results dropdown when Escape key is pressed
 * @param {KeyboardEvent} e - The keyboard event
 */
const enableEscapeKey = (e) => {
  if (e.key === 'Escape') {
    showResults.value = false;
  }
};

/**
 * Tracks when user explicitly submits a search via Enter key
 * This is different from the automatic tracking that happens after typing stops
 */
const handleExplicitSearchSubmit = () => {
  if (trimmedQuery.value === '') return;
  
  searchTracking.handleSearchSubmit({
    query: trimmedQuery.value,
    getSearchResults: getSearchResultsData
  });
};

/**
 * Handles blur events for search input and results
 * Uses a timeout to determine if the user is still interacting with search elements
 * before hiding the results dropdown
 */
const handleBlur = () => {
  // Use setTimeout to allow other click handlers to execute first
  setTimeout(() => {
    try {
      const activeElement = document?.activeElement;
      
      // Hide results if no active element or active element is outside search component
      if (!activeElement || !activeElement.closest('.navigation-header-search')) {
        showResults.value = false;
      }
    } catch (error) {
      console.error('Error handling blur event:', error);
      showResults.value = false;
    }
  }, BLUR_HANDLING_DELAY);
};

// add accessibility and keyboard navigation support
/**
 * Handles keyboard events for the search input
 * Supports navigation with arrow keys, selection with Enter, and closing with Escape
 * @param {KeyboardEvent} e - The keyboard event
 */
const handleSearchKeydown = (e) => {
  enableEscapeKey(e);

  if (e.key === 'ArrowDown') {
    e.preventDefault();
    selectNextResult();
  }

  if (e.key === 'ArrowUp') {
    e.preventDefault();
    selectPreviousResult();
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    if (selectedResult.value) {
      router.push(selectedResult.value.url);
      // Blur the input element if it exists
      if (searchInput.value) {
        // For BaseInput component, we need to access the underlying input element
        const inputElement = searchInput.value.$el.querySelector('input');
        if (inputElement) {
          inputElement.blur();
        }
      }
    } else if (trimmedQuery.value !== '') {
      // Track search when user presses Enter (explicit submission)
      handleExplicitSearchSubmit();
    }
  }
};

/**
 * Handles keyboard events for search result items
 * Currently only supports closing with Escape key
 * @param {KeyboardEvent} e - The keyboard event
 */
const handleResultKeydown = (e) => {
  enableEscapeKey(e);
};

/**
 * Handles a click on a search result
 * Tracks both the search query (if not already tracked) and the result click
 * @param {Object} result - The search result that was clicked
 */
const handleResultClick = (result) => {
  // Don't track clicks with empty queries
  if (trimmedQuery.value === '') {
    router.push(result.url);
    showResults.value = false;
    return;
  }
  
  try {
    // Track the click event
    searchTracking.handleResultClick({
      query: trimmedQuery.value,
      page: result.url,
      getSearchResults: getSearchResultsData
    });
  } catch (error) {
    console.error('Error tracking search result click:', error);
  } finally {
    // Always navigate regardless of tracking success/failure
    router.push(result.url);
    showResults.value = false;
  }
};
</script>

<style lang="scss" scoped>
$block: '.navigation-header-search';

#{$block} {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0 var(--spacing-8);

  &__input-wrapper {
    position: relative;
    width: 100%;
    max-width: 580px;
  }

  &__icon {
    position: absolute;
    left: var(--spacing-12);
    top: var(--spacing-8);
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  &__shortcut {
    visibility: hidden;
    position: absolute;
    right: var(--spacing-16);
    top: var(--spacing-8);
    color: var(--color-neutral-100);
    background: var(--color-neutral-900);
    padding: 2px var(--spacing-4);
    border-radius: var(--spacing-4);
    border: 1px solid var(--color-neutral-500);

    @media screen and (width >= 960px) {
      visibility: visible;
    }

    p {
      font-size: 0.75rem;
    }
  }

  &__clear {
    position: absolute;
    right: var(--spacing-12);
    top: var(--spacing-8);
    color: var(--color-neutral-100) !important;
    z-index: 1;
  }

  &__input {
    width: 100%;
    padding-left: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--color-neutral-700) !important;
    background: var(--color-neutral-900) !important;
    color: var(--color-neutral-100) !important;
  }

  &__results-wrapper {
    position: absolute;
    top: calc(100% + 12px);
    width: 100%;
    max-width: 580px;
    border-radius: 10px !important;
    padding: 0 0 0 var(--spacing-300);
    background: var(--color-neutral-900);
    border: 1px solid var(--color-neutral-700);
    max-height: 400px; /* This limits the height of the wrapper */
    overflow: hidden; /* Ensure content outside the bounds is hidden */
    display: flex;
    flex-direction: column;
  }

  &__results-wrapper-content {
    flex-grow: 1; /* Allow this container to grow and take up available space */
    overflow-y: auto; /* Enable vertical scrolling when content exceeds the parent's height */
    width: 100%;
    padding: var(--spacing-200) var(--spacing-100) var(--spacing-200) 0; /* Optional padding inside the scrollable area */
  }

  &__results-wrapper-content::-webkit-scrollbar {
    width: 12px;
  }

  &__results-wrapper-content::-webkit-scrollbar-track {
    background: var(--color-neutral-900);
  }

  &__results-wrapper-content::-webkit-scrollbar-thumb {
    background: var(--color-neutral-700);
    border-radius: 10px;
  }

  &__results-wrapper-content::-webkit-scrollbar-thumb:hover {
    background: var(--color-neutral-700);
  }

  &__result-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-200);
    text-align: left;

    @media screen and (width >= 960px) {
      padding: var(--spacing-200) var(--spacing-400);
    }

    &:hover,
    &.is-active {
      background: var(--color-neutral-700);
    }

    &.focus-visible {
      outline: auto;
    }

    &.no-results {
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      padding: var(--spacing-24);

      .navigation-header-search__result-item-name {
        margin-bottom: var(--spacing-8);
      }

      .navigation-header-search__result-item-description {
        font-size: 0.875rem;
      }

      &:hover,
      &.is-active {
        background: transparent;
      }

      @media screen and (width >= 960px) {
        padding: var(--spacing-64);
      }
    }
  }

  &__result-item-name {
    font-size: 1rem;
    color: var(--color-neutral-100);
  }

  &__result-item-description {
    font-size: 0.75rem;
    color: var(--color-neutral-100);
  }

  &__link {
    color: var(--color-neutral-100);
    text-decoration: underline;
  }
}
</style>
